import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ModalProvider } from 'styled-react-modal';
import reportWebVitals from 'reportWebVitals';
import dayjs from 'dayjs';

import 'sanitize.css/assets.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/sanitize.css';

import { App } from 'app';

import { HelmetProvider } from 'react-helmet-async';

import { configureAppStore } from 'store/configureStore';
import { ThemeProvider } from 'styles/theme/ThemeProvider';
import { AuthProvider } from './context/AuthContext';
import { ModalBackground } from './app/components/Modal';
import { initUsersnap } from './services/usersnap';

import './locales/i18n';
import { PrintProvider } from './context/PrintContext';

const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

/*TODO: REMOVE*/
globalThis.NEWS_CONTAINER = {};

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider>
      <PrintProvider>
        <HelmetProvider>
          <BrowserRouter>
            <ModalProvider backgroundComponent={ModalBackground}>
              <AuthProvider>
                <App />
              </AuthProvider>
            </ModalProvider>
          </BrowserRouter>
        </HelmetProvider>
      </PrintProvider>
    </ThemeProvider>
  </Provider>,
  MOUNT_NODE,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {});
}

reportWebVitals();
initUsersnap();

Date.prototype.toJSON = function () {
  return dayjs(this).format();
};
